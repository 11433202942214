.tableTotal1 {
  border: 2px solid black;
  width: 100%;
}

.tableTotal1 > .tableCaption {
  text-align: center;
  font-size: 14px;
  color: blue;
  margin-top: 50px;
  caption-side: top;
}

.tableTotal1,
.tableTotal1 > thead > tr > th,
.tableTotal1 > tbody > tr > td,
.tableTotal1 > tbody > tr > td > strong {
  font-size: 12px;
}

.tableTotal1 > tbody > tr.red td {
  color: red;
}

.tableTotal1 thead tr th,
.tableTotal1 tbody tr td:first-child {
  font-weight: bold;
}

.tableTotal1 tbody tr:last-child {
  border-top: 2px solid black;
}

.imprimirListado * {
  font-family: Arial, Helvetica, sans-serif;
}

.imprimirListado table > thead > tr > th,
.imprimirListado table > tbody > tr > td {
  padding: 5px;
}

.imprimirListado hr {
  margin: 0px;
}

.verSoloImpresion {
  display: none !important;
}

@media print {
  .tableTotal1,
  .tableTotal1 > thead > tr > th,
  .tableTotal1 > tbody > tr > td,
  .tableTotal1 > tbody > tr > td > strong {
    font-size: 9pt !important;
  }

  /* Impresion de listado */
  .imprimirListado,
  .imprimirListado *,
  .imprimirListado > div > div,
  .imprimirListado #tabla > div,
  .imprimirListado h6,
  .imprimirListado #tablaBusqueda,
  .imprimirListado #tablaFiltros > td > div > div,
  .imprimirListado #tablaGrupos > div > span,
  .imprimirListado table > thead > tr > th,
  .imprimirListado table > tbody > tr > td,
  .imprimirListado table > tbody > tr > td > strong {
    font-size: 9pt !important;
    box-shadow: none;
  }

  .imprimirListado #tablaBusqueda::before,
  .imprimirListado #tablaFiltros > td > div > div::before {
    display: none;
  }

  .imprimirListado table > thead > tr > th,
  .imprimirListado table > tbody > tr > td {
    padding: 0pt;
  }

  /* Impresion de documento */

  .impresionCabezera {
    display: contents;
  }

  .impresionPie {
    position: absolute;
    bottom: 10px;
  }

  /* Impresion de documento 3xN */
  .imprimirDocumento3xN,
  .imprimirDocumento3xN table > thead > tr > th,
  .imprimirDocumento3xN table > tbody > tr > td,
  .imprimirDocumento3xN table > tbody > tr > td > strong {
    font-size: 9px !important;
  }

  /* Quita primera columna de material table */
  .imprimirSinPrimeraColumna table thead th:first-of-type,
  .imprimirSinPrimeraColumna table tbody td:first-of-type {
    display: none;
  }

  /* Quita primera columna de material table */
  .imprimirSinSegundaColumna table thead th:nth-of-type(2),
  .imprimirSinSegundaColumna table tbody td:nth-of-type(2) {
    display: none;
  }

  /* Quita barra inferior */
  .tablasDetalles {
    overflow-x: hidden;
  }

  .verSoloImpresion {
    display: block !important;
  }
}
