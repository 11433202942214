.imgProfile {
  height: 3rem;
  width: 3rem;
  border-radius: 50% !important;
  cursor: pointer;
}

.nomUsuario {
  margin-right: 0.5rem !important;
  color: #858796 !important;
  display: inline !important;
  font-size: 0.85rem;
  font-weight: 400;
  cursor: pointer;
}

.opciones {
  text-decoration: none !important;
}

.iconos {
  margin-right: 0.5rem;
}

@media only screen and (max-width: 960px) {
  .nomUsuario {
    display: none !important;
  }
}
