.titulo {
  font-family: Arial;
  font-size: 9pt;
  color: black;
  font-weight: bolder;
  font-style: normal;
  background-color: #808080;
  text-align: Center;
  vertical-align: Middle;
}

.compania {
  font-family: Arial;
  font-size: 10pt;
  color: #800000;
  font-weight: bold;
  font-style: normal;
  background-color: transparent;
  text-align: Center;
  vertical-align: Top;
}

.filtros {
  font-family: Arial;
  font-size: 8pt;
  color: #000000;
  font-style: normal;
  background-color: transparent;
  text-align: Center;
  vertical-align: Top;
}

div.fecha {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 6pt !important;
}

div.usuario {
  margin-top: 0;
  margin-bottom: 2pt;
  font-size: 6pt !important;
}
