.campana {
  margin-right: 1rem;
  color: #d1d3e2;
}

.campana:hover {
  color: #b7b9cc;
  cursor: pointer;
}

.container {
  width: 300px;
  max-height: 500px;
}

.dropdownitem {
  padding: 20px 10px;
  white-space: inherit;
  display: grid;
  font-weight: 200;
  height: auto !important;
}

.desde {
  font-size: x-small;
  color: darkgrey;
}

.contenedor {
  z-index: 2000;
  min-width: 400px;
}

.descartarTodos {
  width: 100%;
}

.contador {
  position: absolute;
  transform: scale(0.7);
  transform-origin: top right;
  right: 0.25rem;
  margin-top: 1rem;
}

.iconoNotificacion {
  font-size: 2rem !important;
}

@media only screen and (max-width: 960px) {
  .iconoNotificacion {
    font-size: 2.5rem !important;
  }
}
