.active > div {
  background-color: rgba(0, 0, 0, 0.5);
}

/* sobreescribo este estilo porque bootstrap inyecta un estilo que daña el de MUI */
legend {
  float: none !important;
  font-size: 1rem !important;
  max-width: 1000px !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  font-size: 1.3rem;
  color: black;
}

/*El popper no se ve cuando se usa en modals ya que queda detras de este */
.MuiAutocomplete-popper {
  z-index: 10000 !important;
}

.MuiPopover-root {
  z-index: 10000 !important;
}

/*El popper no se ve cuando se usa en modals ya que queda detras de este */
.MuiPopover-paper {
  z-index: 10000 !important;
}

/* Arregla el tamaño de los autocomplete */
.MuiAutocomplete-root > div > div.MuiInputBase-root {
  padding: 0;
}

/********************************* Imprimir Documento *********************************/
/* Parte la pagina de impresion en 3 partes cabeza, cuerpo y pie. El pie es sticky cuando pasa a la segunda pagina se pega al contenido. */
.imprimirDocumento {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: Arial, Helvetica, sans-serif;
}

.imprimirDocumento * {
  font-size: 12px;
}

/* Cabezera */
.imprimirDocumento > div:first-of-type {
  height: "auto";
}

/* Linas al 100% de ancho */
.imprimirDocumento > div:first-of-type table {
  width: 100%;
}

.imprimirDocumento > div:first-of-type tbody > tr > td {
  padding-bottom: 1pt;
}

.imprimirDocumento > div:first-of-type,
.imprimirDocumento > div:first-of-type table tr td {
  line-height: 1;
}

.imprimirDocumento > div:last-of-type,
.imprimirDocumento > div:last-of-type table tr td {
  line-height: 1;
}

/* Lineas debajo del texto */
.imprimirDocumento > div:first-of-type tbody > tr > td > u {
  text-decoration: none;
  border-bottom: 1px solid black;
  width: 100%;
  display: block;
}

.imprimirDocumento > div:nth-of-type(2) table thead tr th {
  white-space: nowrap;
}

.imprimirDocumento > div:last-of-type {
  position: sticky;
  width: 100%;
  height: 3in;
}

@media print {
  .imprimirDocumento * {
    font-size: 12px;
  }
}
/********************************* Imprimir Documento *********************************/

/*Ajusto el tamaño de los dropdown para que no se vean tan grandes */
.dropdown-menu button {
  height: 40px;
  line-height: initial;
}

.card {
  box-shadow: 0 0.15rem 0.75rem 0 rgba(58, 59, 69, 0.15) !important;
  border-radius: 10px !important;
  margin-bottom: 5px;
}

.card .MuiCardHeader-root {
  color: darkgreen;
  background-color: #f8f9fc;
  border-bottom: 1px solid #e3e6f0;
}

.cardStyle2 {
  width: 100%;
}

.cardStyle2 .MuiCardHeader-root {
  color: brown;
  background-color: white;
  border-bottom: 1px solid #e3e6f0;
}

.cardStyle3 {
  box-shadow: 0 0.15rem 0.75rem 0 rgba(58, 59, 69, 0.15) !important;
  border-radius: 10px !important;
  margin-bottom: 5px;
}

.cardStyle3 .MuiCardHeader-root {
  color: darkgreen;
  background-color: #f8f9fc;
  border-bottom: 1px solid #e3e6f0;
  /* font-size: 16px; */
}

.cardStyle3 .MuiCardContent-root {
  padding: 0;
}

.impresionCabezera {
  display: none;
}

.impresionPie {
  position: relative;
  bottom: 10px;
}

.botonTour {
  position: fixed;
  top: 5rem;
  right: 0px;
  font-size: 3rem !important;
}

.contenedor {
  width: 100% !important;
}

.contenedorGrid {
  display: contents;
}

.MaterialTablePanel {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 0.85rem;
  color: grey;
}

.MaterialTablePanel table {
  width: 100%;
}

.Mui-required {
  color: red !important;
}

.ReportesPaper {
  padding: 16px;
  flex-grow: 1;
  margin: 5px;
  display: block;
  max-width: inherit;
}

.ReportesTab {
  margin: auto !important;
}

.tablasDetalles {
  overflow-x: auto;
  overflow-y: auto;
}

.tablasDetalles thead {
  background-color: lightgrey;
}

.tablasDetalles thead th {
  color: blue !important;
  padding: 0 24px;
}

.tablasDetalles tbody tr:hover {
  background-color: lightgrey;
}

.tablasDetalles tfoot {
  background-color: #ebebeb;
}

.tablasDetallesFilas {
  padding: 0 24px;
}

.MuiTableCell-sizeSmall {
  padding: 0 6px !important;
}

.MuiTabPanel-root {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

/* Barra de herramienta */

.botonNuevo {
  background-image: linear-gradient(to bottom, #3f51b5, #3f51b5);
  color: #ffffff !important;
  border-radius: 20px !important;
}

.botonNuevo:disabled {
  background-image: linear-gradient(to bottom, #dadbdd, #dadbdd);
  color: #a2a2a4 !important;
}

.botonNuevo:hover {
  background-image: linear-gradient(to bottom, #303f9f, #303f9f);
}

.botonModificar {
  background-image: linear-gradient(to bottom, #a7b342, #a7b342);
  color: #ffffff !important;
  border-radius: 20px !important;
}

.botonModificar:disabled {
  background-image: linear-gradient(to bottom, #dadbdd, #dadbdd);
  color: #a2a2a4 !important;
}

.botonModificar:hover {
  background-image: linear-gradient(to bottom, #8e992f, #8e992f);
}

.botonEliminar {
  background-image: linear-gradient(to bottom, #b34242, #b34242);
  color: #ffffff !important;
  border-radius: 20px !important;
}

.botonEliminar:disabled {
  background-image: linear-gradient(to bottom, #dadbdd, #dadbdd);
  color: #a2a2a4 !important;
}

.botonEliminar:hover {
  background-image: linear-gradient(to bottom, #922c2c, #922c2c);
}

.botonGrabar {
  background-image: linear-gradient(to bottom, #69ac3d, #69ac3d);
  color: #ffffff !important;
  border-radius: 20px !important;
}

.botonGrabar:disabled {
  background-image: linear-gradient(to bottom, #dadbdd, #dadbdd);
  color: #a2a2a4 !important;
}

.botonGrabar:hover {
  background-image: linear-gradient(to bottom, #56922d, #56922d);
}

.botonCancelar {
  background-image: linear-gradient(to bottom, #a06738, #a06738);
  color: #ffffff !important;
  border-radius: 20px !important;
}

.botonCancelar:disabled {
  background-image: linear-gradient(to bottom, #dadbdd, #dadbdd);
  color: #a2a2a4 !important;
}

.botonCancelar:hover {
  background-image: linear-gradient(to bottom, #885429, #885429);
}

button.botonRefrescar {
  border-radius: 20px !important;
  background-color: #e0e0e0;
  color: #000000;
}

button.botonRefrescar:hover {
  background-color: #c0c0c0;
}

button.botonConsultar {
  border-radius: 20px !important;
  background-color: #e0e0e0;
  color: #000000;
}

button.botonConsultar:hover {
  background-color: #c0c0c0;
}

button.botonImprimir {
  border-radius: 20px !important;
  background-color: #e0e0e0;
  color: #000000;
}

button.botonImprimir:hover {
  background-color: #c0c0c0;
}

button.botonConvertir {
  border-radius: 20px !important;
  background-color: #e0e0e0;
  color: #000000;
}

button.botonConvertir:hover {
  background-color: #c0c0c0;
}

.dropDown {
  border: 4px;
  border-radius: 4px;
}

.iconos {
  margin-right: 15px;
  font-size: 3rem !important;
}

.botonFlotante {
  width: 60px !important;
  height: 60px !important;
}

.grupoImprimir {
  box-shadow: 1px 1px 5px 0px #000000 !important;
}

/* Modal styles */
.modalBody {
  overflow-y: auto;
  max-height: 80vh;
}
