.body {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-position: right;
    background-repeat: no-repeat;
    flex-direction: column;
    height:100vh;
    margin: 0;
}

.container {
    margin: auto;
    border-radius: 5px;
    background: rgba(0,0,0,.55);
    color: #fff;
    padding: 32px;
    min-width: 450px;
}

.titulo {
    font-weight: 300;
    margin-bottom: 28px;
    text-align: center;
}

.contenedorInterno {
    border-radius: 5px;
    padding: 15px;
}

.submitbutton {
    margin: 5px auto;
    width: 100%;
}

.inputs {
    margin: 10px 0;
}