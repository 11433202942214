@media screen and (min-height: 2200px) {
  div[style="overflow-y: auto;"] {
    max-height: 1700px;
  }
}

@media screen and (max-height: 2200px) {
  div[style="overflow-y: auto;"] {
    max-height: 1700px;
  }
}

@media screen and (max-height: 2000px) {
  div[style="overflow-y: auto;"] {
    max-height: 1500px;
  }
}

@media screen and (max-height: 1800px) {
  div[style="overflow-y: auto;"] {
    max-height: 1300px;
  }
}

@media screen and (max-height: 1600px) {
  div[style="overflow-y: auto;"] {
    max-height: 1100px;
  }
}

@media screen and (max-height: 1400px) {
  div[style="overflow-y: auto;"] {
    max-height: 900px;
  }
}

@media screen and (max-height: 1200px) {
  div[style="overflow-y: auto;"] {
    max-height: 700px;
  }
}

@media screen and (max-height: 1000px) {
  div[style="overflow-y: auto;"] {
    max-height: 500px;
  }
}

@media screen and (max-height: 800px) {
  div[style="overflow-y: auto;"] {
    max-height: 300px;
  }
}

@media screen and (max-height: 600px) {
  div[style="overflow-y: auto;"] {
    max-height: 100px;
  }
}

@media print {
  /* div[style~="overflow-y: auto;"] {
    max-height: none !important;
    min-height: none !important;
  } */

  /* .MuiTableCell-root {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  } */

  .MuiIconButton-root {
    display: none !important;
  }

  .MuiInputAdornment-root {
    display: none !important;
  }
}

.contenedorGrid {
  display: contents;
}
