.body {
    display: flex;
    margin: 0;
}

.container {
    margin: auto;
    border-radius: 5px;
    color: #71a73b;
    padding: 32px;
}

.titulo {
    font-weight: 300;
    margin-bottom: 28px;
    text-align: center;
}

.imgCompania {
    height: 150px;
    width: 150px;
}

.contenedorInterno {
    border-radius: 5px;
    padding: 15px;
}

.submitbutton {
    margin: 5px auto;
    width: 100%;
}

.banner {
    text-align: right;
}