/****** Book Loader ******/
.contenedor {
    min-height: 100%;
    min-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: "Roboto", Arial;
}

.contenedor .dribbble {
	position: fixed;
	display: block;
	right: 24px;
	bottom: 24px;
}

.contenedor .dribbble img {
	display: block;
	width: 76px;
}

.loader {
	--background: linear-gradient(135deg, #cedc86, #9dba32);
	--shadow: #02570e47;
	--text: #6c7486;
	--page: rgba(255, 255, 255, 0.36);
	--page-fold: rgba(255, 255, 255, 0.52);
	--duration: 3s;
	width: 200px;
	height: 140px;
	position: relative;
}

.loader:before, .loader:after {
	--r: -6deg;
	content: "";
	position: absolute;
	bottom: 8px;
	width: 120px;
	top: 80%;
	box-shadow: 0 16px 12px var(--shadow);
	transform: rotate(var(--r));
}

.loader:before {
	left: 4px;
}

.loader:after {
	--r: 6deg;
	right: 4px;
}

.loader div {
	width: 100%;
	height: 100%;
	border-radius: 13px;
	position: relative;
	z-index: 1;
	perspective: 600px;
	box-shadow: 0 4px 6px var(--shadow);
	background-image: var(--background);
}

.loader div ul {
	margin: 0;
	padding: 0;
	list-style: none;
	position: relative;
}

.loader div ul li {
	--r: 180deg;
	--o: 0;
	--c: var(--page);
	position: absolute;
	top: 10px;
	left: 10px;
	transform-origin: 100% 50%;
	color: var(--c);
	opacity: var(--o);
	transform: rotateY(var(--r));
	animation: var(--duration) ease infinite;
}

.loader div ul li:nth-child(2) {
	--c: var(--page-fold);
	animation-name: page-2;
}

.loader div ul li:nth-child(3) {
	--c: var(--page-fold);
	animation-name: page-3;
}

.loader div ul li:nth-child(4) {
	--c: var(--page-fold);
	animation-name: page-4;
}

.loader div ul li:nth-child(5) {
	--c: var(--page-fold);
	animation-name: page-5;
}

.loader div ul li svg {
	width: 90px;
	height: 120px;
	display: block;
}

.loader div ul li:first-child {
	--r: 0deg;
	--o: 1;
}

.loader div ul li:last-child {
	--o: 1;
}

.loader span {
	display: block;
	left: 0;
	right: 0;
	top: 100%;
	margin-top: 20px;
	text-align: center;
	color: var(--text);
}

@keyframes page-2 {
	0% {
		transform: rotateY(180deg);
		opacity: 0;
	}

	20% {
		opacity: 1;
	}

	35%,
  100% {
		opacity: 0;
	}

	50%,
  100% {
		transform: rotateY(0deg);
	}
}

@keyframes page-3 {
	15% {
		transform: rotateY(180deg);
		opacity: 0;
	}

	35% {
		opacity: 1;
	}

	50%,
  100% {
		opacity: 0;
	}

	65%,
  100% {
		transform: rotateY(0deg);
	}
}

@keyframes page-4 {
	30% {
		transform: rotateY(180deg);
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	65%,
  100% {
		opacity: 0;
	}

	80%,
  100% {
		transform: rotateY(0deg);
	}
}

@keyframes page-5 {
	45% {
		transform: rotateY(180deg);
		opacity: 0;
	}

	65% {
		opacity: 1;
	}

	80%,
  100% {
		opacity: 0;
	}

	95%,
  100% {
		transform: rotateY(0deg);
	}
}

html {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
}

* {
	box-sizing: inherit;
}

*:before, *:after {
	box-sizing: inherit;
}

/****** Car Loader ******/
.carLoader {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
			align-items: center;
	-webkit-box-pack: center;
			justify-content: center;
  }
  
  .carBody {
	-webkit-animation: shake 0.2s ease-in-out infinite alternate;
			animation: shake 0.2s ease-in-out infinite alternate;
  }
  .carLine {
	-webkit-transform-origin: center right;
			transform-origin: center right;
	stroke-dasharray: 22;
	-webkit-animation: line 0.8s ease-in-out infinite;
			animation: line 0.8s ease-in-out infinite;
	-webkit-animation-fill-mode: both;
			animation-fill-mode: both;
  }
  .carLineTop {
	-webkit-animation-delay: 0s;
			animation-delay: 0s;
  }
  .carLineMiddle {
	-webkit-animation-delay: 0.2s;
			animation-delay: 0.2s;
  }
  .carLineBottom {
	-webkit-animation-delay: 0.4s;
			animation-delay: 0.4s;
  }
  
  @-webkit-keyframes shake {
	0% {
	  -webkit-transform: translateY(-1%);
			  transform: translateY(-1%);
	}
	100% {
	  -webkit-transform: translateY(3%);
			  transform: translateY(3%);
	}
  }
  
  @keyframes shake {
	0% {
	  -webkit-transform: translateY(-1%);
			  transform: translateY(-1%);
	}
	100% {
	  -webkit-transform: translateY(3%);
			  transform: translateY(3%);
	}
  }
  @-webkit-keyframes line {
	0% {
	  stroke-dashoffset: 22;
	}
	25% {
	  stroke-dashoffset: 22;
	}
	50% {
	  stroke-dashoffset: 0;
	}
	51% {
	  stroke-dashoffset: 0;
	}
	80% {
	  stroke-dashoffset: -22;
	}
	100% {
	  stroke-dashoffset: -22;
	}
  }
  @keyframes line {
	0% {
	  stroke-dashoffset: 22;
	}
	25% {
	  stroke-dashoffset: 22;
	}
	50% {
	  stroke-dashoffset: 0;
	}
	51% {
	  stroke-dashoffset: 0;
	}
	80% {
	  stroke-dashoffset: -22;
	}
	100% {
	  stroke-dashoffset: -22;
	}
  }
  
  /****** Cog Loader ******/
  containerCog {
	height: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
		-ms-flex-pack: center;
			justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
		-ms-flex-align: center;
			align-items: center; }
  
  .machineCog {
	width: 60vmin;
	fill: #3eb049; }
  
  .smallShadowCog, .mediumShadowCog, .largeShadowCog {
	fill: rgba(0, 0, 0, 0.05); }
  
  .smallCog {
	-webkit-animation: counter-rotation 2.5s infinite linear;
		 -moz-animation: counter-rotation 2.5s infinite linear;
		   -o-animation: counter-rotation 2.5s infinite linear;
			  animation: counter-rotation 2.5s infinite linear;
	-webkit-transform-origin: 100.136px 225.345px;
		-ms-transform-origin: 100.136px 225.345px;
			transform-origin: 100.136px 225.345px; }
  
  .smallShadowCog {
	-webkit-animation: counter-rotation 2.5s infinite linear;
		 -moz-animation: counter-rotation 2.5s infinite linear;
		   -o-animation: counter-rotation 2.5s infinite linear;
			  animation: counter-rotation 2.5s infinite linear;
	-webkit-transform-origin: 110.136px 235.345px;
		-ms-transform-origin: 110.136px 235.345px;
			transform-origin: 110.136px 235.345px; }
  
  .mediumCog {
	-webkit-animation: rotation 3.75s infinite linear;
		 -moz-animation: rotation 3.75s infinite linear;
		   -o-animation: rotation 3.75s infinite linear;
			  animation: rotation 3.75s infinite linear;
	-webkit-transform-origin: 254.675px 379.447px;
		-ms-transform-origin: 254.675px 379.447px;
			transform-origin: 254.675px 379.447px; }
  
  .mediumShadowCog {
	-webkit-animation: rotation 3.75s infinite linear;
		 -moz-animation: rotation 3.75s infinite linear;
		   -o-animation: rotation 3.75s infinite linear;
			  animation: rotation 3.75s infinite linear;
	-webkit-transform-origin: 264.675px 389.447px;
		-ms-transform-origin: 264.675px 389.447px;
			transform-origin: 264.675px 389.447px; }
  
  .largeCog {
	-webkit-animation: counter-rotation 5s infinite linear;
	   -moz-animation: counter-rotation 5s infinite linear;
		   -o-animation: counter-rotation 5s infinite linear;
			  animation: counter-rotation 5s infinite linear;
	-webkit-transform-origin: 461.37px 173.694px;
		-ms-transform-origin: 461.37px 173.694px;
			transform-origin: 461.37px 173.694px; }
  
  .largeShadowCog {  
	-webkit-animation: counter-rotation 5s infinite linear;
		 -moz-animation: counter-rotation 5s infinite linear;
		   -o-animation: counter-rotation 5s infinite linear;
			  animation: counter-rotation 5s infinite linear;
	-webkit-transform-origin: 471.37px 183.694px;
		-ms-transform-origin: 471.37px 183.694px;
			transform-origin: 471.37px 183.694px; }
  
  @-webkit-keyframes rotation {
	  from {-webkit-transform: rotate(0deg);}
	  to   {-webkit-transform: rotate(359deg);}
  }
  @-moz-keyframes rotation {
	  from {-moz-transform: rotate(0deg);}
	  to   {-moz-transform: rotate(359deg);}
  }
  @-o-keyframes rotation {
	  from {-o-transform: rotate(0deg);}
	  to   {-o-transform: rotate(359deg);}
  }
  @keyframes rotation {
	  from {transform: rotate(0deg);}
	  to   {transform: rotate(359deg);}
  }
  
  @-webkit-keyframes counter-rotation {
	  from {-webkit-transform: rotate(359deg);}
	  to   {-webkit-transform: rotate(0deg);}
  }
  @-moz-keyframes counter-rotation {
	  from {-moz-transform: rotate(359deg);}
	  to   {-moz-transform: rotate(0deg);}
  }
  @-o-keyframes counter-rotation {
	  from {-o-transform: rotate(359deg);}
	  to   {-o-transform: rotate(0deg);}
  }
  @keyframes counter-rotation {
	  from {transform: rotate(359deg);}
	  to   {transform: rotate(0deg);}
  }